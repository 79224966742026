<script lang="ts">
	import { onMount } from 'svelte'
	import { fade } from 'svelte/transition'
	import { CldImage } from 'svelte-cloudinary'
	import { phg } from '$lib/utils/posthog'
	import * as m from '$lib/paraglide/messages'

	let idx = 0
	let strings = [
		m.home_hero_tagline_word1(),
		m.home_hero_tagline_word2(),
		m.home_hero_tagline_word3(),
	]

	onMount(() => {
		const interval = setInterval(() => {
			if (idx < strings.length - 1) {
				idx++
			} else {
				idx = 0
			}
		}, 3000)

		return () => {
			clearInterval(interval)
		}
	})
</script>

<main class="wrapper pb-24 pl-4 pr-4 pt-4 sm:pb-28 sm:pl-6 sm:pr-6 sm:pt-6">
	<section class="flex flex-col items-center gap-6 text-center sm:pt-28">
		<div
			class="flex min-h-[calc(100svh-6rem)] flex-col items-start justify-center gap-8 sm:min-h-[unset] sm:items-center sm:gap-6"
		>
			<a
				href="/compare"
				class="rounded-full bg-blue-900 px-2.5 py-0.5 text-sm font-semibold text-blue-300 transition-colors hover:bg-blue-800 focus-visible:ring"
			>
				New: Compare Pages
			</a>

			<h1
				class="flex flex-col items-start text-4xl font-bold leading-[1.05] 2xs:text-5xl sm:items-center sm:text-6xl"
			>
				<p class="text-start sm:text-center">{m.home_hero_tagline_start()}</p>

				<p>
					<span>{m.home_hero_tagline_middle()}</span>

					{#key idx}
						<span in:fade={{ duration: 500 }} class="text-branding-gradient">
							{strings[idx]}
						</span>
					{/key}
				</p>
			</h1>

			<h2
				class="links-inside text-left text-lg font-semibold text-base-content-light xs:max-w-[600px] sm:text-center sm:text-base sm:font-medium"
			>
				<!-- eslint-disable-next-line svelte/no-at-html-tags -->
				{@html m.home_hero_description({
					ethereum: '/chain/ethereum',
					bitcoin: '/chain/bitcoin',
					solana: '/chain/solana',
				})}
			</h2>

			<a
				href="/dashboard"
				class="button button-base button-fill z-2"
				on:click={() => {
					phg?.capture('open_dashboard', {
						place: 'main_banner',
					})
				}}
			>
				{m.home_hero_button()}
			</a>
		</div>

		<div class="relative sm:mt-20" style="width: 780px; max-width: 100%;">
			<CldImage
				width="1353"
				height="823"
				src="macbook_fw3jvv"
				fetchpriority="high"
				decoding="async"
				loading="lazy"
				priority
				alt={m.home_hero_macbook_screenshot_alt()}
			/>

			<div class="absolute bottom-0 right-1 sm:right-4" style="max-width: 24%;">
				<CldImage
					width="352"
					height="708"
					src="iphone_ym68ar"
					fetchpriority="high"
					decoding="async"
					loading="lazy"
					priority
					alt={m.home_hero_iphone_screenshot_alt()}
				/>
			</div>
		</div>
	</section>

	<section class="flex flex-col items-center pt-32 sm:pt-40">
		<div class="flex flex-col items-center">
			<h3 class="text-center text-xl font-semibold sm:text-2xl">
				{m.home_partners_title()}
			</h3>

			<div
				class="mt-8 grid grid-cols-2 grid-rows-4 items-center justify-center gap-10 sm:grid-cols-8 sm:grid-rows-2 sm:gap-8"
			>
				<div class="flex items-center justify-center">
					<CldImage
						width="112"
						height="48"
						src="partners/web3-foundation_ribdtd"
						format="svg"
						alt="Web3 Foundation"
						decoding="async"
						loading="lazy"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						width="137"
						height="24"
						src="partners/bnb-chain_vxgxul"
						format="svg"
						alt="BNB Chain"
						decoding="async"
						loading="lazy"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						width="126"
						height="38"
						src="partners/hedera_jfdier"
						format="svg"
						alt="Hedera"
						decoding="async"
						loading="lazy"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						width="140"
						height="30"
						src="partners/polkadot_avbc50"
						format="svg"
						alt="Polkadot"
						decoding="async"
						loading="lazy"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						width="130"
						height="32"
						src="partners/stellar_xpmipo"
						format="svg"
						alt="Stellar"
						decoding="async"
						loading="lazy"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						width="122"
						height="30"
						src="partners/aptos_xowanj"
						format="svg"
						alt="Aptos"
						decoding="async"
						loading="lazy"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						width="140"
						height="36"
						src="partners/fantom_ygrkwi"
						format="svg"
						alt="Fantom"
						decoding="async"
						loading="lazy"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						width="100"
						height="38"
						src="partners/telos_pqaltr"
						format="svg"
						alt="Telos"
						decoding="async"
						loading="lazy"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						width="126"
						height="34"
						src="partners/arthera_glxkja"
						format="svg"
						alt="Arthera"
						decoding="async"
						loading="lazy"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						width="126"
						height="34"
						src="partners/iotex_rhkxp1"
						format="svg"
						alt="IoTeX"
						decoding="async"
						loading="lazy"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						width="80"
						height="30"
						src="partners/sei_xxwsr5"
						format="svg"
						alt="Sei"
						decoding="async"
						loading="lazy"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						width="124"
						height="18"
						src="partners/starknet_nrtv5e"
						format="svg"
						alt="Starknet"
						decoding="async"
						loading="lazy"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						width="151"
						height="22"
						src="partners/moonbeam_uf5dsy"
						format="svg"
						alt="Moonbeam"
						decoding="async"
						loading="lazy"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						width="162"
						height="30"
						src="partners/concordium_il9kqd"
						format="svg"
						alt="Concordium"
						decoding="async"
						loading="lazy"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						width="138"
						height="27"
						src="partners/cardano_yeltwh"
						format="svg"
						alt="Cardano"
						decoding="async"
						loading="lazy"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						width="128"
						height="29"
						src="partners/bware_labs_jtlfvi"
						format="svg"
						alt="Bware Labs"
						decoding="async"
						loading="lazy"
					/>
				</div>
			</div>
		</div>
	</section>

	<section class="flex flex-col items-center pt-24 sm:pt-40">
		<h3 class="text-center text-2xl font-bold sm:text-4xl" style="max-width: 700px;">
			{m.home_numbers_title()}
		</h3>

		<p
			class="mt-6 px-1 text-center text-base text-base-content-light sm:px-0"
			style="max-width: 700px;"
		>
			{m.home_numbers_description()}
		</p>

		<div
			class="mt-10 grid w-full grid-cols-1 grid-rows-[1fr_auto_1fr_auto_1fr] items-center justify-center gap-6 sm:mt-14 sm:grid-cols-[1fr_auto_1fr_auto_1fr] sm:grid-rows-[auto] sm:gap-4"
		>
			<div class="flex flex-col items-center justify-center">
				<p class="text-center text-5xl font-semibold sm:text-6xl">10</p>

				<p class="mt-4 text-center text-base text-base-content-light md:text-lg">
					{m.home_numbers_metrics()}
				</p>
			</div>

			<span class="mx-auto block h-0.5 w-10/12 self-stretch bg-base-light sm:h-auto sm:w-0.5" />

			<div class="flex flex-col items-center justify-center">
				<p class="text-center text-5xl font-semibold sm:text-6xl">40+</p>

				<p class="mt-4 text-center text-base text-base-content-light md:text-lg">
					{m.home_numbers_chains()}
				</p>
			</div>

			<span class="mx-auto block h-0.5 w-10/12 self-stretch bg-base-light sm:h-auto sm:w-0.5" />

			<div class="flex flex-col items-center justify-center">
				<p class="text-center text-5xl font-semibold sm:text-6xl">500GB+</p>

				<p class="mt-4 text-center text-base text-base-content-light md:text-lg">
					{m.home_numbers_data()}
				</p>
			</div>
		</div>
	</section>

	<section class="flex flex-col items-center pt-24 sm:pt-40">
		<h3 class="text-center text-2xl font-bold sm:text-4xl">{m.home_metrics_title()}</h3>

		<p class="mt-6 text-center text-base text-base-content-light" style="max-width: 800px;">
			{m.home_metrics_description()}
		</p>

		<div
			class="mt-6 grid grid-cols-1 gap-6 md:mt-10 md:grid-cols-2 md:gap-10"
			style="max-width: 1000px;"
		>
			<a
				href="/blog/transactions-per-second-tps"
				target="_blank"
				class="flex flex-col rounded-lg border-2 border-base-light p-3 transition-transform hover:scale-101 focus-visible:ring md:p-4"
			>
				<p class="mb-2 text-lg font-bold sm:text-xl md:text-center">
					{m.home_metrics_tps_name()}
				</p>
				<p class="mt-auto text-base-content-light">
					{m.home_metrics_tps_description()}
				</p>
			</a>

			<a
				href="/blog/transactions-per-second-tps"
				target="_blank"
				class="flex flex-col rounded-lg border-2 border-base-light p-3 transition-transform hover:scale-101 focus-visible:ring md:p-4"
			>
				<p class="mb-2 text-lg font-bold sm:text-xl md:text-center">
					{m.home_metrics_max_tps_name()}
				</p>
				<p class="text-base-content-light">
					{m.home_metrics_max_tps_description()}
				</p>
			</a>

			<a
				href="/blog/transactions-per-second-tps"
				target="_blank"
				class="flex flex-col rounded-lg border-2 border-base-light p-3 transition-transform hover:scale-101 focus-visible:ring md:p-4"
			>
				<p class="mb-2 text-lg font-bold sm:text-xl md:text-center">
					{m.home_metrics_theor_tps_name()}
				</p>
				<p class="text-base-content-light">
					{m.home_metrics_theor_tps_description()}
				</p>
			</a>

			<a
				href="/blog/block-time"
				target="_blank"
				class="flex flex-col rounded-lg border-2 border-base-light p-3 transition-transform hover:scale-101 focus-visible:ring md:p-4"
			>
				<p class="mb-2 text-lg font-bold sm:text-xl md:text-center">
					{m.home_metrics_blocktime_name()}
				</p>
				<p class="text-base-content-light">
					{m.home_metrics_blocktime_description()}
				</p>
			</a>

			<a
				href="/blog/time-to-finality-ttf"
				target="_blank"
				class="flex flex-col rounded-lg border-2 border-base-light p-3 transition-transform hover:scale-101 focus-visible:ring md:p-4"
			>
				<p class="mb-2 text-lg font-bold sm:text-xl md:text-center">
					{m.home_metrics_ttf_name()}
				</p>
				<p class="text-base-content-light">
					{m.home_metrics_ttf_description()}
				</p>
			</a>

			<a
				href="/blog/blockchain-governance"
				target="_blank"
				class="flex flex-col rounded-lg border-2 border-base-light p-3 transition-transform hover:scale-101 focus-visible:ring md:p-4"
			>
				<p class="mb-2 text-lg font-bold sm:text-xl md:text-center">
					{m.home_metrics_governance_name()}
				</p>
				<p class="text-base-content-light">
					{m.home_metrics_governance_description()}
				</p>
			</a>

			<div class="flex flex-col rounded-lg border-2 border-base-light p-3 md:p-4">
				<p class="mb-2 text-lg font-bold sm:text-xl md:text-center">
					{m.home_metrics_launched_date_name()}
				</p>
				<p class="text-base-content-light">
					{m.home_metrics_launched_date_description()}
				</p>
			</div>
		</div>
	</section>

	<section class="mx-auto flex flex-col items-center pt-24 sm:pt-40" style="max-width: 700px;">
		<h3 class="text-center text-2xl font-bold sm:text-4xl">{m.home_chain_pages_title()}</h3>

		<p class="mt-6 text-base text-base-content-light">
			<!-- eslint-disable-next-line svelte/no-at-html-tags -->
			{@html m.home_chain_pages_description()}
		</p>

		<a
			href="/chain/ethereum"
			class="mt-6 w-full rounded-lg border-2 border-base-light p-1 transition-transform hover:scale-101 focus-visible:ring"
		>
			<CldImage
				width="1895"
				height="1502"
				src="eth-page_nw1xya"
				decoding="async"
				loading="lazy"
				alt={m.home_chain_pages_screenshot_alt()}
			/>
		</a>

		<p class="links-inside mt-6 text-base text-base-content-light">
			<!-- eslint-disable-next-line svelte/no-at-html-tags -->
			{@html m.home_chain_pages_screenshot_subtitle({
				ethereum: '/chain/ethereum',
			})}
		</p>
	</section>

	<section class="mx-auto flex flex-col items-center pt-24 sm:pt-40" style="max-width: 700px;">
		<h3 class="text-center text-2xl font-bold sm:text-4xl">{m.home_compare_pages_title()}</h3>

		<p class="mt-6 text-base text-base-content-light">
			<!-- eslint-disable-next-line svelte/no-at-html-tags -->
			{@html m.home_compare_pages_description()}
		</p>

		<a
			href="/compare/ethereum-vs-bitcoin"
			class="mt-6 w-full rounded-lg border-2 border-base-light p-1 transition-transform hover:scale-101 focus-visible:ring"
		>
			<CldImage
				width="1522"
				height="1701"
				src="ethereum-vs-bitcoin_riprui"
				decoding="async"
				loading="lazy"
				alt={m.home_compare_pages_screenshot_alt()}
			/>
		</a>

		<p class="links-inside mt-6 text-base text-base-content-light">
			<!-- eslint-disable-next-line svelte/no-at-html-tags -->
			{@html m.home_compare_pages_screenshot_subtitle({
				compare: '/compare',
				ethereumVsBitcoin: '/compare/ethereum-vs-bitcoin',
			})}
		</p>
	</section>

	<section class="flex flex-col items-center pt-24 sm:pt-40">
		<h3 class="text-center text-2xl font-bold sm:text-4xl">{m.home_bottom_cta_title()}</h3>

		<p class="mt-6 text-center text-base text-base-content-light" style="max-width: 500px;">
			{m.home_bottom_cta_description()}
		</p>

		<a
			href="/dashboard"
			class="button button-base button-fill mt-8"
			on:click={() => {
				phg?.capture('open_dashboard', {
					place: 'bottom_banner',
				})
			}}
		>
			{m.home_bottom_cta_button()}
		</a>
	</section>
</main>
